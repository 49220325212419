import { cleanErrorObject } from 'common/dist/validation/helpers';
import { validateDescription, validateName } from '../../campaign-optimization-channels/v1/validate';
import { ContactPolicy } from 'common/dist/types/module.optimization';
import { numberRegex } from '../../../../../organisms/modelManagement/newAugurWizard/optCommunications/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';
import { CampaignOptimizationContactPoliciesAugurSettings, CampaignOptimizationContactPoliciesValidationError } from './type';

const validateContactPolicyType = (
  contactPolicyType: ContactPolicy['contactPolicyType'] | undefined
): string | undefined => {
  // The validateConstraintLevel field is required
  if (!contactPolicyType) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoType.defaultMessage;
  }
};

const validateOperator = (
  operator: ContactPolicy['operator'] | undefined
): string | undefined => {
  // The validateConstraintLevel field is required
  if (!operator) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoOperator.defaultMessage;
  }
};

export const validateValue = (value?: string): string | undefined => {
  // The name field is required
  if (!value) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoValue.defaultMessage;
  }

  // Valid characters
  if (!numberRegex.test(value)) {
    return moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrValueInvalid.defaultMessage;
  }
};

// ---

export const validateContactPolicies = (
  value: CampaignOptimizationContactPoliciesAugurSettings
): DeepPartial<CampaignOptimizationContactPoliciesValidationError> | undefined => {
  const error: DeepPartial<CampaignOptimizationContactPoliciesValidationError> = {};

  // Is there at least one contact policy added?
  if (!value || (value || []).length === 0) {
    error.global =
      moduleOptimizationMessages.msgNewAugurStepContactPoliciesErrNoContactPolicies.defaultMessage;
  } else {
    value?.forEach((contactPolicy) => {
      const contactPolicyError = validateSingleContactPolicies(
        contactPolicy,
        value
      );
      if (!_.isEmpty(contactPolicyError)) {
        error.rows = {
          ...error.rows,
          [contactPolicy.id]: contactPolicyError,
        }
      }
    });
  }
  return cleanErrorObject(error);
};


export const validateSingleContactPolicies = (
  contactPolicy: Partial<ContactPolicy>,
  allContactPolicies?: Partial<ContactPolicy>[]
): {
  name?: string;
  description?: string;
  contactPolicyType?: string;
  operator?: string;
  value?: string;
} => {

  // Validate name
  const nameError = validateName(
    contactPolicy.name,
    (allContactPolicies || [])
      .filter((x) => x.id !== contactPolicy.id)
      .map((ch) => ch.name)
      .filter((name): name is string => name !== undefined)
  );

  // Validate description
  const descriptionError = validateDescription(contactPolicy.description);

  // Validate contactPolicyType
  const contactPolicyTypeError = validateContactPolicyType(contactPolicy.contactPolicyType);

  // Validate operator
  const operatorError = validateOperator(contactPolicy.operator);

  // Validate value
  const valueError = validateValue(contactPolicy.value);

  return {
    name: nameError,
    description: descriptionError,
    contactPolicyType: contactPolicyTypeError,
    operator: operatorError,
    value: valueError,
  };
};
