import {
  ConstraintsValueType,
  ContactPoliciesValueType,
  CustomerTableValueType,
  TargetFunctionValueType,
} from 'common/dist/types/augurSettings.opt';
import { PostAugurRequestBody } from 'common/dist/types/requestBodies/augurs';

import { Props } from './OptimizationNewAugurWizard';
import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldAugurName,
  getDefaultMpwAsyncValidate,
} from '../../../../../newAugur/defaultWizard/default.form';
import { validateModule } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/validate';
import { BucketOrKeyspaceSelectValue } from '../../../../../organisms/modelManagement/newAugurWizard/dataSource/BucketOrKeyspaceSelect';
import { validateChannels } from '../../../../../organisms/modelManagement/newAugurWizard/optChannels/validate';
import { validateCommunicationGroups } from '../../../../../organisms/modelManagement/newAugurWizard/optCommunicationGroups/validate';
import { validateCommunications } from '../../../../../organisms/modelManagement/newAugurWizard/optCommunications/validate';
import { validateConstraints } from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/validate';
import { validateContactPolicies } from '../../../../../organisms/modelManagement/newAugurWizard/optContactPolicies/validate';
import { validateCustomerTable } from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/validate';
import { validatePropensities } from '../../../../../organisms/modelManagement/newAugurWizard/optPropensities/validate';
import { validateTargetFunction } from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/validate';
//Global const to handle validation
export const _globalValidationError = '_globalValidationError';

// --- Field names
export const fieldModule = 'module';

export const fieldChannels = 'channels';
export const fieldPropensities = 'propensities';
export const fieldCommunications = 'communications';
export const fieldCommunicationGroups = 'communicationGroups';
export const fieldCustomerBucketOrKeyspace = 'customerBucketOrKeyspace';
export const fieldCustomerTable = 'customerTable';
export const fieldConstraints = 'constraints';
export const fieldContactPolicy = 'contactPolicy';
export const fieldTargetFunction = 'targetFunction';

// --- Type of the form values
export type OptimizationFormValues = DefaultFormValues & {
  [fieldModule]: {
    /** Code of the selected module */
    code?: string;
    /** Selected version number */
    versionNumber?: string;
  };
  [fieldCustomerTable]: CustomerTableValueType;
  [fieldConstraints]: ConstraintsValueType;
  [fieldContactPolicy]: ContactPoliciesValueType;
  [fieldTargetFunction]: TargetFunctionValueType;
  [fieldCustomerBucketOrKeyspace]: BucketOrKeyspaceSelectValue;
};

// --- Sync validation
export const optimizationValidate = (values: OptimizationFormValues) => {
  // I'm not really sure why this is nested down into the '_reduxForm' key and not kept at the top level of the object ...
  // const reduxFormProps = props?.['_reduxForm'];
  // const { pipelineTuningSchemas, parameterTuningSchema } = (reduxFormProps || {});
  const errors = defaultValidate(values) || {};
  errors[fieldModule] = validateModule(values[fieldModule]);
  errors[fieldChannels] = validateChannels(values[fieldChannels]);
  errors[fieldPropensities] = validatePropensities(values[fieldPropensities]);
  errors[fieldCommunications] = validateCommunications(
    values[fieldCommunications]
  );
  errors[fieldCommunicationGroups] = validateCommunicationGroups(
    values[fieldCommunicationGroups]
  );

  errors[fieldCustomerTable] = validateCustomerTable(
    values[fieldCustomerTable]
  );

  // @ts-ignore
  errors[fieldConstraints] = validateConstraints(values[fieldConstraints]);
  // @ts-ignore
  errors[fieldContactPolicy] = validateContactPolicies(
    values[fieldContactPolicy]
  );
  // @ts-ignore
  errors[fieldTargetFunction] = validateTargetFunction(
    values[fieldTargetFunction]
  );

  return errors;
};

// --- Async validation
export function getOptimizationMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

// --- Warn (used for the "sub forms" for adding channels, propensities, etc.)
export const optimizationWarn = (
  values: OptimizationFormValues,
  props: { _reduxForm: Props }
) => {
  const warnings = {};
  // warnings[fieldConstraints] = warnConstraints(values[fieldConstraints]); // TODO!!
  warnings[fieldChannels] = validateChannels(values[fieldChannels]);
  warnings[fieldPropensities] = validatePropensities(values[fieldPropensities]);
  warnings[fieldCommunications] = validateCommunications(
    values[fieldCommunications]
  );
  warnings[fieldCommunicationGroups] = validateCommunicationGroups(
    values[fieldCommunicationGroups]
  );
  warnings[fieldConstraints] = validateConstraints(values[fieldConstraints]);
  warnings[fieldContactPolicy] = validateContactPolicies(
    values[fieldContactPolicy]
  );
  warnings[fieldTargetFunction] = validateTargetFunction(
    values[fieldTargetFunction]
  );

  return warnings;
};

export const optimizationAsyncBlurFields = [...defaultAsyncBlurFields];
export const optimizationAsyncChangeFields = [...defaultAsyncChangeFields];

export const getOptimizationSubmitSummary = (
  formValues: OptimizationFormValues,
  habitatCode: string
): PostAugurRequestBody => {
  const moduleCode = (formValues[fieldModule] || {}).code;
  const moduleVersionNumber = (formValues[fieldModule] || {}).versionNumber;

  return {
    // Habitat Code
    habitatCode,

    // Module
    moduleCode: moduleCode,
    moduleVersionCode: moduleVersionNumber,

    // Augur name
    name: formValues[fieldAugurName],
    attributes: {},
    resources: undefined,
    // --- Settings
    settings: {
      channels: formValues[fieldChannels],
      propensities: formValues[fieldPropensities],
      communications: formValues[fieldCommunications],
      communicationGroups: formValues[fieldCommunicationGroups],
      customerTable: {
        ...formValues[fieldCustomerBucketOrKeyspace],
        ...formValues[fieldCustomerTable],
      },
      constraints: formValues[fieldConstraints],
      contactPolicies: formValues[fieldContactPolicy],
      targetFunction: {
        minimizeOrMaximize: formValues[fieldTargetFunction]?.minimizeOrMaximize,
        summandGroups: formValues[fieldTargetFunction]?.summandGroups,
      },
    },
  };
};
