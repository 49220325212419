import Ajv, { ValidateFunction } from 'ajv';
import { ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';

import { REPORT_ELEMENT_TYPES } from './type';
import { AugurReportEntry, Config } from '../../../../pages/augur/type';
import { TileSizeRestriction } from '../../../grid-layout-editor/type';
import { SharedInputProps } from '../../common/utils';
import { PredictedValuesChartMulti } from '../bar-chart/v1/multi/PredictedValuesChart';
import { schemaConfigBarChart } from '../bar-chart/v1/schema';
import { BarChartSingle } from '../bar-chart/v1/single/BarChart';
import { barChartImg } from '../bar-chart/v1/single/img/barChartImg';
import {
  BarChartConfig,
  BarChartReportData,
  configTypeBarChart,
  defaultConfigBarChart,
  defaultReportDataDefaultBarChart,
  reportDataTypeBarChart,
} from '../bar-chart/v1/type';
import { BinaryTreeShadowModelSingle } from '../classification-tree/v1/BinaryTreeShadowModel';
import { classificationTreeImg } from '../classification-tree/v1/img/classificationTreeImg';
import { schemaConfigClassificationTree } from '../classification-tree/v1/schema';
import {
  ClassificationTreeConfig,
  ClassificationTreeReportData,
  configTypeClassificationTree,
  defaultConfigClassificationTree,
  defaultReportDataDefaultClassificationTree,
  reportDataTypeClassificationTree,
} from '../classification-tree/v1/type';
import { schemaConfigImage } from '../image/v1/schema';
import { ImageSingle } from '../image/v1/single/Image';
import { imageImg } from '../image/v1/single/img/imageImg';
import {
  configTypeImage,
  ImageConfig,
  ImageReportData,
  defaultConfigImage,
  defaultReportDataDefaultImage,
  reportDataTypeImage,
} from '../image/v1/type';
import { constraintExhaustionImg } from '../constraint-exhaustion/v1/img/ConstraintExhaustionImg';
import { schemaConfigConstraintExhaustion } from '../constraint-exhaustion/v1/schema';
import { ConstraintExhaustionSingle } from '../constraint-exhaustion/v1/single/ConstraintExhaustion';
import {
  configTypeConstraintExhaustion,
  ConstraintExhaustionConfig,
  ConstraintExhaustionReportData,
  defaultConfigConstraintExhaustion,
  defaultReportDataDefaultConstraintExhaustion,
  reportDataTypeConstraintExhaustion,
} from '../constraint-exhaustion/v1/type';
import { featureImportanceImg } from '../feature-importance/v1/img/featureImportanceImg';
import { schemaConfigFeatureImportance } from '../feature-importance/v1/schema';
import { FeatureImportanceSingle } from '../feature-importance/v1/single/FeatureImportance';
import {
  configTypeFeatureImportance,
  defaultConfigFeatureImportance,
  defaultReportDataDefaultFeatureImportance,
  FeatureImportanceConfig,
  FeatureImportanceReportData,
  reportDataTypeFeatureImportance,
} from '../feature-importance/v1/type';
import { configSchemaHeatmap } from '../heatmap/v1/schema';
import { HeatmapSingle } from '../heatmap/v1/single/Heatmap';
import { heatmapImg } from '../heatmap/v1/single/img/heatmapImg';
import {
  configTypeHeatmap,
  defaultConfigHeatmap,
  defaultReportDataDefaultHeatmap,
  HeatmapConfig,
  HeatmapReportData,
  reportDataTypeHeatmap,
} from '../heatmap/v1/type';
import { LineChartMulti } from '../line-chart/v1/multi/MultiLineChart';
import { configSchemaLineChart } from '../line-chart/v1/schema';
import { lineChartImg } from '../line-chart/v1/single/img/lineChartImg';
import { LineChartSingle } from '../line-chart/v1/single/LineChart';
import {
  configTypeLineChart,
  defaultConfigLineChart,
  defaultReportDataDefaultLineChart,
  LineChartConfig,
  LineChartReportData,
  reportDataTypeLineChart,
} from '../line-chart/v1/type';
import { schemaConfigParameterTuningResults } from '../parameter-tuning-results/v1/schema';
import { parameterTuningResultsImg } from '../parameter-tuning-results/v1/single/img/parameterTuningResultsImg';
import { ParameterTuningResultsSingle } from '../parameter-tuning-results/v1/single/ParameterTuningResults';
import {
  configTypeParameterTuningResults,
  defaultConfigParameterTuningResults,
  defaultReportDataDefaultParameterTuningResults,
  ParameterTuningResultsConfig,
  ParameterTuningResultsReportData,
  reportDataTypeParameterTuningResults,
} from '../parameter-tuning-results/v1/type';
import { PerformanceDriftMulti } from '../performance-drift/v1/multi/MultiPerformanceDrift';
import { schemaConfigPerformanceDrift } from '../performance-drift/v1/schema';
import { performanceDriftImg } from '../performance-drift/v1/single/img/performanceDriftImg';
import { PerformanceDriftSingle } from '../performance-drift/v1/single/PerformanceDrift';
import {
  configTypePerformanceDrift,
  defaultConfigPerformanceDrift,
  defaultReportDataDefaultPerformanceDrift,
  PerformanceDriftConfig,
  PerformanceDriftReportData,
  reportDataTypePerformanceDrift,
} from '../performance-drift/v1/type';
import { StackedBarChartMulti } from '../pie-chart/v1/multi/StackedBarChart';
import { schemaConfigPieChart } from '../pie-chart/v1/schema';
import { pieChartImg } from '../pie-chart/v1/single/img/pieChartImg';
import { PieChartSingle } from '../pie-chart/v1/single/PieChart';
import {
  configTypePieChart,
  defaultConfigPieChart,
  defaultReportDataDefaultPieChart,
  PieChartConfig,
  PieChartReportData,
  reportDataTypePieChart,
} from '../pie-chart/v1/type';
import { pipelineTuningBestResultImg } from '../pipeline-tuning-best-result/v1/img/pipelineTuningBestResultImg';
import { schemaConfigPipelineTuningBestResult } from '../pipeline-tuning-best-result/v1/schema';
import { PipelineTuningBestResultSingle } from '../pipeline-tuning-best-result/v1/single/PipelineTuningBestResult';
import {
  configTypePipelineTuningBestResult,
  defaultConfigPipelineTuningBestResult,
  defaultReportDataDefaultPipelineTuningBestResult,
  PipelineTuningBestResultConfig,
  PipelineTuningBestResultReportData,
  reportDataTypePipelineTuningBestResult,
} from '../pipeline-tuning-best-result/v1/type';
import { pipelineTuningSingleResultsImg } from '../pipeline-tuning-single-results/v1/img/pipelineTuningSingleResultsImg';
import { schemaConfigPipelineTuningSingleResults } from '../pipeline-tuning-single-results/v1/schema';
import { PipelineTuningSingleResultsSingle } from '../pipeline-tuning-single-results/v1/single/PipelineTuningSingleResults';
import {
  configTypePipelineTuningSingleResults,
  defaultConfigPipelineTuningSingleResults,
  defaultReportDataDefaultPipelineTuningSingleResults,
  PipelineTuningSingleResultsConfig,
  PipelineTuningSingleResultsReportData,
  reportDataTypePipelineTuningSingleResults,
} from '../pipeline-tuning-single-results/v1/type';
import { shapChartImg } from '../shap-chart/v1/img/shapChartImg';
import { schemaConfigShapChart } from '../shap-chart/v1/schema';
import { ShapChartSingle } from '../shap-chart/v1/single/ShapChart';
import {
  configTypeShapChart,
  defaultConfigShapChart,
  defaultReportDataDefaultShapChart,
  reportDataTypeShapChart,
  ShapChartConfig,
  ShapChartReportData,
} from '../shap-chart/v1/type';
import { textOutputImg } from '../text-output/v1/img/textOutputImg';
import { schemaConfigTextOutput } from '../text-output/v1/schema';
import { TextOutputSingle } from '../text-output/v1/single/TextOutput';
import {
  configTypeTextOutput,
  defaultConfigTextOutput,
  defaultReportDataDefaultTextOutput,
  reportDataTypeTextOutput,
  TextOutputConfig,
  TextOutputReportData,
} from '../text-output/v1/type';

export const ajv = new Ajv();

export type ReportElementProps<
  TInput,
  TConfig extends Record<string, unknown>
> = SharedInputProps & {
  input: AugurReportEntry<TInput>;
  config: TConfig;
};

export type MultiReportElementProps<
  TInput,
  TConfig extends Record<string, unknown>
> = SharedInputProps & {
  input: AugurReportEntry<TInput | undefined>[];
  config: TConfig;
};

export type AugurReportElementMeta<
  TReportData = Record<string, unknown>,
  TConfigType extends Record<string, unknown> = Record<string, unknown>
> = {
  name: MessageDescriptor;
  description: MessageDescriptor;
  type: string;
  version: string;
  imageIcon: string;
  tileSizeRestrictions: TileSizeRestriction;
  SingleReportComponent: ComponentType<
    ReportElementProps<TReportData, TConfigType>
  >;
  MultiReportComponent?: ComponentType<
    MultiReportElementProps<TReportData, TConfigType>
  >;
  defaultConfig: Config<TConfigType>;
  validateConfig: ValidateFunction;
  configurationType: string;
  reportDataDefault: TReportData;
  reportDataType: string;
};

// Then the types of the components need to be inserted correctly
export type ReportElementMetas = {
  [REPORT_ELEMENT_TYPES.LINE_CHART]: {
    v1: AugurReportElementMeta<LineChartReportData, LineChartConfig>;
  };
  [REPORT_ELEMENT_TYPES.BAR_CHART]: {
    v1: AugurReportElementMeta<BarChartReportData, BarChartConfig>;
  };
  [REPORT_ELEMENT_TYPES.PIE_CHART]: {
    v1: AugurReportElementMeta<PieChartReportData, PieChartConfig>;
  };
  [REPORT_ELEMENT_TYPES.SHAP_CHART]: {
    v1: AugurReportElementMeta<ShapChartReportData, ShapChartConfig>;
  };
  [REPORT_ELEMENT_TYPES.CLASSIFICATION_TREE]: {
    v1: AugurReportElementMeta<
      ClassificationTreeReportData,
      ClassificationTreeConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.HEATMAP]: {
    v1: AugurReportElementMeta<HeatmapReportData, HeatmapConfig>;
  };
  [REPORT_ELEMENT_TYPES.TEXT_OUTPUT]: {
    v1: AugurReportElementMeta<TextOutputReportData, TextOutputConfig>;
  };
  [REPORT_ELEMENT_TYPES.IMAGE]: {
    v1: AugurReportElementMeta<ImageReportData, ImageConfig>;
  };
  [REPORT_ELEMENT_TYPES.CONSTRAINT_EXHAUSTION]: {
    v1: AugurReportElementMeta<
      ConstraintExhaustionReportData,
      ConstraintExhaustionConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.FEATURE_IMPORTANCE]: {
    v1: AugurReportElementMeta<
      FeatureImportanceReportData,
      FeatureImportanceConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.PIPELINE_TUNING_SINGLE_RESULTS]: {
    v1: AugurReportElementMeta<
      PipelineTuningSingleResultsReportData,
      PipelineTuningSingleResultsConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.PIPELINE_TUNING_BEST_RESULT]: {
    v1: AugurReportElementMeta<
      PipelineTuningBestResultReportData,
      PipelineTuningBestResultConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.PARAMETER_TUNING_RESULTS]: {
    v1: AugurReportElementMeta<
      ParameterTuningResultsReportData,
      ParameterTuningResultsConfig
    >;
  };
  [REPORT_ELEMENT_TYPES.PERFORMANCE_DRIFT]: {
    v1: AugurReportElementMeta<
      PerformanceDriftReportData,
      PerformanceDriftConfig
    >;
  };
};

export const reportElementMetas: ReportElementMetas = {
  [REPORT_ELEMENT_TYPES.LINE_CHART]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Line Chart' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Line Chart',
      },
      type: REPORT_ELEMENT_TYPES.LINE_CHART,
      version: 'v1',
      imageIcon: lineChartImg,
      tileSizeRestrictions: { minH: 3, minW: 3 },
      SingleReportComponent: LineChartSingle,
      MultiReportComponent: LineChartMulti,
      defaultConfig: defaultConfigLineChart,
      validateConfig: ajv.compile(configSchemaLineChart),
      configurationType: configTypeLineChart,
      reportDataDefault: defaultReportDataDefaultLineChart,
      reportDataType: reportDataTypeLineChart,
    },
  },
  [REPORT_ELEMENT_TYPES.BAR_CHART]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Bar Chart' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Bar Chart',
      },
      type: REPORT_ELEMENT_TYPES.BAR_CHART,
      version: 'v1',
      imageIcon: barChartImg,
      tileSizeRestrictions: { minH: 3, minW: 3 },
      SingleReportComponent: BarChartSingle,
      MultiReportComponent: PredictedValuesChartMulti,
      defaultConfig: defaultConfigBarChart,
      validateConfig: ajv.compile(schemaConfigBarChart),
      configurationType: configTypeBarChart,
      reportDataDefault: defaultReportDataDefaultBarChart,
      reportDataType: reportDataTypeBarChart,
    },
  },

  [REPORT_ELEMENT_TYPES.PIE_CHART]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Pie Chart' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Pie Chart',
      },
      type: REPORT_ELEMENT_TYPES.PIE_CHART,
      version: 'v1',
      imageIcon: pieChartImg,
      tileSizeRestrictions: { minH: 3, minW: 3 },
      SingleReportComponent: PieChartSingle,
      MultiReportComponent: StackedBarChartMulti,
      defaultConfig: defaultConfigPieChart,
      validateConfig: ajv.compile(schemaConfigPieChart),
      configurationType: configTypePieChart,
      reportDataDefault: defaultReportDataDefaultPieChart,
      reportDataType: reportDataTypePieChart,
    },
  },

  [REPORT_ELEMENT_TYPES.SHAP_CHART]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Shap Chart' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Shap-Chart',
      },
      type: REPORT_ELEMENT_TYPES.SHAP_CHART,
      version: 'v1',
      imageIcon: shapChartImg,
      tileSizeRestrictions: { minH: 6, minW: 6 },
      SingleReportComponent: ShapChartSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigShapChart,
      validateConfig: ajv.compile(schemaConfigShapChart),
      configurationType: configTypeShapChart,
      reportDataDefault: defaultReportDataDefaultShapChart,
      reportDataType: reportDataTypeShapChart,
    },
  },

  [REPORT_ELEMENT_TYPES.CLASSIFICATION_TREE]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Classification Tree' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Classification Tree',
      },
      type: REPORT_ELEMENT_TYPES.CLASSIFICATION_TREE,
      version: 'v1',
      imageIcon: classificationTreeImg,
      tileSizeRestrictions: { minH: 5, minW: 5 },
      SingleReportComponent: BinaryTreeShadowModelSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigClassificationTree,
      validateConfig: ajv.compile(schemaConfigClassificationTree),
      configurationType: configTypeClassificationTree,
      reportDataDefault: defaultReportDataDefaultClassificationTree,
      reportDataType: reportDataTypeClassificationTree,
    },
  },

  [REPORT_ELEMENT_TYPES.HEATMAP]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Heatmap' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Heatmap',
      },
      type: REPORT_ELEMENT_TYPES.HEATMAP,
      version: 'v1',
      imageIcon: heatmapImg,
      tileSizeRestrictions: { minH: 8, minW: 8 },
      SingleReportComponent: HeatmapSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigHeatmap,
      validateConfig: ajv.compile(configSchemaHeatmap),
      configurationType: configTypeHeatmap,
      reportDataDefault: defaultReportDataDefaultHeatmap,
      reportDataType: reportDataTypeHeatmap,
    },
  },

  [REPORT_ELEMENT_TYPES.TEXT_OUTPUT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Text Output' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows text output',
      },
      type: REPORT_ELEMENT_TYPES.TEXT_OUTPUT,
      version: 'v1',
      imageIcon: textOutputImg,
      tileSizeRestrictions: { minH: 2, minW: 2 },
      SingleReportComponent: TextOutputSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigTextOutput,
      validateConfig: ajv.compile(schemaConfigTextOutput),
      configurationType: configTypeTextOutput,
      reportDataDefault: defaultReportDataDefaultTextOutput,
      reportDataType: reportDataTypeTextOutput,
    },
  },

  [REPORT_ELEMENT_TYPES.IMAGE]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Image' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows an Image',
      },
      type: REPORT_ELEMENT_TYPES.IMAGE,
      version: 'v1',
      imageIcon: imageImg,
      tileSizeRestrictions: { minH: 2, minW: 2 },
      SingleReportComponent: ImageSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigImage,
      validateConfig: ajv.compile(schemaConfigImage),
      configurationType: configTypeImage,
      reportDataDefault: defaultReportDataDefaultImage,
      reportDataType: reportDataTypeImage,
    },
  },

  [REPORT_ELEMENT_TYPES.CONSTRAINT_EXHAUSTION]: {
    v1: {
      name: {
        id: 'no-id',
        defaultMessage: 'Constraint Exhaustion (Campaign Optimization)',
      },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows the Constraint Exhaustion',
      },
      type: REPORT_ELEMENT_TYPES.CONSTRAINT_EXHAUSTION,
      version: 'v1',
      imageIcon: constraintExhaustionImg,
      tileSizeRestrictions: { minH: 5, minW: 8 },
      SingleReportComponent: ConstraintExhaustionSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigConstraintExhaustion,
      validateConfig: ajv.compile(schemaConfigConstraintExhaustion),
      configurationType: configTypeConstraintExhaustion,
      reportDataDefault: defaultReportDataDefaultConstraintExhaustion,
      reportDataType: reportDataTypeConstraintExhaustion,
    },
  },

  [REPORT_ELEMENT_TYPES.FEATURE_IMPORTANCE]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Feature importance' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows text feature importance for each feature.',
      },
      type: REPORT_ELEMENT_TYPES.FEATURE_IMPORTANCE,
      version: 'v1',
      imageIcon: featureImportanceImg,
      tileSizeRestrictions: { minH: 4, minW: 4 },
      SingleReportComponent: FeatureImportanceSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigFeatureImportance,
      validateConfig: ajv.compile(schemaConfigFeatureImportance),
      configurationType: configTypeFeatureImportance,
      reportDataDefault: defaultReportDataDefaultFeatureImportance,
      reportDataType: reportDataTypeFeatureImportance,
    },
  },

  [REPORT_ELEMENT_TYPES.PIPELINE_TUNING_SINGLE_RESULTS]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Pipeline Tuning (Single Results)' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows the Pipeline Tuning Results (Single Results)',
      },
      type: REPORT_ELEMENT_TYPES.PIPELINE_TUNING_SINGLE_RESULTS,
      version: 'v1',
      imageIcon: pipelineTuningSingleResultsImg,
      tileSizeRestrictions: { minH: 8, minW: 8 },
      SingleReportComponent: PipelineTuningSingleResultsSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigPipelineTuningSingleResults,
      validateConfig: ajv.compile(schemaConfigPipelineTuningSingleResults),
      configurationType: configTypePipelineTuningSingleResults,
      reportDataDefault: defaultReportDataDefaultPipelineTuningSingleResults,
      reportDataType: reportDataTypePipelineTuningSingleResults,
    },
  },

  [REPORT_ELEMENT_TYPES.PIPELINE_TUNING_BEST_RESULT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Pipeline Tuning (Best Result)' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows the Pipeline Tuning Results (Best Model)',
      },
      type: REPORT_ELEMENT_TYPES.PIPELINE_TUNING_BEST_RESULT,
      version: 'v1',
      imageIcon: pipelineTuningBestResultImg,
      tileSizeRestrictions: { minH: 8, minW: 8 },
      SingleReportComponent: PipelineTuningBestResultSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigPipelineTuningBestResult,
      validateConfig: ajv.compile(schemaConfigPipelineTuningBestResult),
      configurationType: configTypePipelineTuningBestResult,
      reportDataDefault: defaultReportDataDefaultPipelineTuningBestResult,
      reportDataType: reportDataTypePipelineTuningBestResult,
    },
  },

  [REPORT_ELEMENT_TYPES.PARAMETER_TUNING_RESULTS]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Parameter Tuning Results' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows the Parameter Tuning Results',
      },
      type: REPORT_ELEMENT_TYPES.PARAMETER_TUNING_RESULTS,
      version: 'v1',
      imageIcon: parameterTuningResultsImg,
      tileSizeRestrictions: { minH: 5, minW: 8 },
      SingleReportComponent: ParameterTuningResultsSingle,
      MultiReportComponent: undefined,
      defaultConfig: defaultConfigParameterTuningResults,
      validateConfig: ajv.compile(schemaConfigParameterTuningResults),
      configurationType: configTypeParameterTuningResults,
      reportDataDefault: defaultReportDataDefaultParameterTuningResults,
      reportDataType: reportDataTypeParameterTuningResults,
    },
  },

  [REPORT_ELEMENT_TYPES.PERFORMANCE_DRIFT]: {
    v1: {
      name: { id: 'no-id', defaultMessage: 'Performance Drift' },
      description: {
        id: 'no-id',
        defaultMessage: 'Shows a Performance Drift',
      },
      type: REPORT_ELEMENT_TYPES.PERFORMANCE_DRIFT,
      version: 'v1',
      imageIcon: performanceDriftImg,
      tileSizeRestrictions: { minH: 3, minW: 3 },
      SingleReportComponent: PerformanceDriftSingle,
      MultiReportComponent: PerformanceDriftMulti,
      defaultConfig: defaultConfigPerformanceDrift,
      validateConfig: ajv.compile(schemaConfigPerformanceDrift),
      configurationType: configTypePerformanceDrift,
      reportDataDefault: defaultReportDataDefaultPerformanceDrift,
      reportDataType: reportDataTypePerformanceDrift,
    },
  },

} as const;
