import { Module } from './module';
import { OptimizationMethod } from './pipeline';
import { ToBeRefined } from './todo_type';

export type ModuleVersion = {
  code: string;
  /** Version number of the module version */
  number: string;
  /** Image name of the module version */
  imageName: string;
  config: ModuleConfiguration;
  /** We need the executiontype for spark **/
  executionType: 'other' | 'spark';
  commitSha?: string;
  createdAt: Date;
  description: string | null;
  openApiSpec?: Record<string, unknown>;
};

export const SERVING_TYPES = {
  REST: 'rest',
} as const;

export type ServingType = (typeof SERVING_TYPES)[keyof typeof SERVING_TYPES];

export interface GeneralConfiguration extends GeneralConfigurationResources {
  supportsLearning: boolean;
  supportsEvaluation: boolean;
  supportsPrediction: boolean;
  supportsRealtimePrediction: boolean;
  realtimePrediction?: GeneralConfigurationResources['realtimePrediction'] & {
    availableServingTypes: ServingType[];
  };
}

export type GeneralConfigurationResources = {
  learning?: { resources: K8sResources };
  evaluation?: { resources: K8sResources };
  prediction?: { resources: K8sResources };
  realtimePrediction?: {
    resources: K8sResources;
  };
};

export interface K8sResources {
  cpuRequest?: string;
  cpuLimit?: string;
  memoryRequest?: string;
  memoryLimit?: string;
  useGpu?: boolean;
  gpuRequest?: string;
  gpuLimit?: string;
  gpuProduct?: Gpu;
}

// Same type as in CodeCapsules
export type Gpu = { model: string };

/**
 * File that contains the general settings, the module layout and module settings
 * This file can be missing entirely. Will be added automatically after entering edit mode in the dev augur
 * configuring settings and saving them. Can also be added via templates.
 */
export interface ModuleConfiguration {
  apiVersion: string; // Just a replication from the ModuleRepository for convenience. They are not versioned separately

  // ... Other fields
  generalConfiguration: GeneralConfiguration;

  // Augur Settings Configuration
  augurSettingsConfiguration: Record<string, unknown>[];

  // Augur Reports Configuration
  augurReportConfiguration: {
    learning: Record<string, unknown>[];
    evaluation: Record<string, unknown>[];
    prediction: Record<string, unknown>[];
  };
}

export type ModuleVersionWithModule = ModuleVersion & {
  module: Module;
};

/** Single pipeline schema, that defines the structure as well as the nodes and how they can be configured */
export type PipelineTuningSchemaType = {
  id: string;
  displayName: string;
  optimizationMethod: OptimizationMethod;
  edges: EdgeType[];
  nodes: (NodeType | GroupType)[];
};
/** Connects two nodes / groups */
type EdgeType = {
  sourceID: string;
  targetID: string;
};
/** Group of nodes as one step of the pipeline (the nodes of the group can be seen as alternatives to each other, for
 * example a FixedValueImputer and a MeanValueImputer - that fulfill a similar kind of data processing in different
 * ways) */
export type GroupType = {
  type: 'group';
  id: string;
  nodes: NodeType[];
  displayName: string;

  /** Callback for when a node is clicked in the graph */
  setSelectedNode?: (node: NodeType) => void;
  /** Set when the node is clicked in the graph */
  selectedNodeId?: string;
};
/** Single node as one step of the pipeline */
export type NodeType = {
  type: 'node';
  /** Sub-type of the node */
  nodeType: 'transformer' | 'classifier';
  id: string;
  isOptional: boolean;
  isEditable: boolean;
  isTuneable: boolean;
  displayName: string;
  description: ToBeRefined;
  staticParameters: StaticParameterType[];
  tuningParameters: TuningParameterType[];

  /** Set when the node is clicked in the graph */
  selectedNodeId?: string;
  /** Is the node currently inactive? */
  isInactive?: boolean;
};
/** Type that defines the specs for one parameter that can be set for the node */
export type StaticParameterType = {
  id: string;
  name: string;
  displayName: string;
  description: ToBeRefined;
  validValues: ValueDescriptionType[];
  default: string | number;
};
/**
 * Type for the error of the whole Field
 */
export type PipelineErrorType = {
  [pipelineIndex: number]: SinglePipelineErrorType;
  general?: ToBeRefined;
};
/**
 * Type for the error of one single pipeline
 */
export type SinglePipelineErrorType = {
  nodes?: {
    [nodeId: string]: SingleNodeErrorType;
  };
};
export type SingleNodeErrorType = {
  staticParameters?: {
    [parameterId: string]: ToBeRefined;
  };
  tuningParameters?: {
    [parameterId: string]: ToBeRefined;
  };
};
export type TuningParameterType = {
  id: string;
  name: string;
  displayName: string;
  description: ToBeRefined;
  validValues: ValueDescriptionType[];
  default: (string | number)[];
};
/** Describes the valid values for a static or tuning parameter */
export type ValueDescriptionType =
  | NumberValueDescriptionType
  | StringValueDescriptionType;
/** Describes the valid values for a static or tuning parameter of type double or int */
type NumberValueDescriptionType = {
  type: 'double' | 'int';
  minValue: number;
  maxValue: number;
};
/** Describes the valid values for a static or tuning parameter of type string */
type StringValueDescriptionType = {
  type: 'string';
  values: string[];
};

export type ValueType = {
  type: 'int' | 'double';
  min: number;
  max: number;
};

export interface Parameter {
  name: string;
  speakingName: string;
  description: string;
  validValues: ValueType[];
  default: number[];
}

export interface AvailableAlgorithm {
  name: string;
  speakingName: string;
  description: string;
  parameters: Parameter[];
}

/**
 * Top level schema describing the available algorithms for tuning
 */
export interface ParameterTuningSchema {
  availableSearchStrategies: {
    name: string;
    speakingName: string;
    description: string;
  }[];
  availableAlgorithms: AvailableAlgorithm[];
}

export interface AlgorithmNames {
  speakingName: string;
  parameters: { [param: string]: string };
}

export interface SchemaNames {
  [algo: string]: AlgorithmNames;
}
