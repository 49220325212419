/**
 * Validation for the "sub form": add channel
 * @param value
 */
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import {
  Channel,
  Communication,
  CommunicationGroup,
  Constraint,
} from 'common/dist/types/module.optimization';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import _ from 'lodash';
import { DeepPartial } from 'react-hook-form';

import {
  CampaignOptimizationConstraintsAugurSettings,
  CampaignOptimizationConstraintsConfig,
  CampaignOptimizationConstraintsValidationError,
} from './type';
import { numberRegex } from '../../../../../organisms/modelManagement/newAugurWizard/optCommunications/validate';
import {
  validateDescription,
  validateName,
} from '../../campaign-optimization-channels/v1/validate';
import { CampaignOptimizationCommunicationsConfig } from '../../campaign-optimization-communications/v1/type';

const validateConstraintLevel = (
  constraintLevel?: Constraint['constraintLevel']
): string | undefined => {
  // The validateConstraintLevel field is required
  if (!constraintLevel) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoLevel
      .defaultMessage;
  }
};

const validateChannelId = (
  channelId: string,
  constraintLevel?: Constraint['constraintLevel'],
  config?: CampaignOptimizationConstraintsConfig
): string | undefined => {
  // The validateConstraintLevel field is required
  if (constraintLevel && constraintLevel === 'channel') {
    if (!channelId)
      return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoChannel
        .defaultMessage;
    else if (
      channelId &&
      !config?.channels?.find((element: Channel) => element?.id === channelId)
    )
      return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoChannel
        .defaultMessage;
  }
};

const validateCommunicationId = (
  communicationId: string,
  constraintLevel?: Constraint['constraintLevel'],
  config?: CampaignOptimizationConstraintsConfig
): string | undefined => {
  // The validateConstraintLevel field is required
  if (constraintLevel && constraintLevel === 'communication') {
    if (!communicationId)
      return moduleOptimizationMessages
        .msgNewAugurStepConstraintsErrNoCommunication.defaultMessage;
    else if (
      communicationId &&
      !config?.communications?.find(
        (element: Communication) => element?.id === communicationId
      )
    )
      return moduleOptimizationMessages
        .msgNewAugurStepConstraintsErrNoCommunication.defaultMessage;
  }
};

const validateCommunicationGroupId = (
  communicationGroupId: string,
  constraintLevel?: Constraint['constraintLevel'],
  config?: CampaignOptimizationConstraintsConfig
): string | undefined => {
  // The validateConstraintLevel field is required
  if (constraintLevel && constraintLevel === 'communicationGroup') {
    if (!communicationGroupId)
      return moduleOptimizationMessages
        .msgNewAugurStepConstraintsErrNoCommunicationGroup.defaultMessage;
    else if (
      communicationGroupId &&
      communicationGroupId &&
      !config?.communicationGroups?.find(
        (element: CommunicationGroup) => element?.id === communicationGroupId
      )
    )
      return moduleOptimizationMessages
        .msgNewAugurStepConstraintsErrNoCommunicationGroup.defaultMessage;
  }
};

const validateConstraintType = (
  constraintType: Constraint['constraintType']
): string | undefined => {
  // The validateConstraintLevel field is required
  if (!constraintType) {
    return moduleOptimizationMessages
      .msgNewAugurStepConstraintsErrNoConstraintType.defaultMessage;
  }
};

const validateOperator = (
  operator: Constraint['operator']
): string | undefined => {
  // The validateConstraintLevel field is required
  if (!operator) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoOperator
      .defaultMessage;
  }
};

export const validateValue = (value?: string): string | undefined => {
  // The name field is required
  if (!value) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoValue
      .defaultMessage;
  }

  // Valid characters
  if (!numberRegex.test(value)) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrValueInvalid
      .defaultMessage;
  }
};

// ---
export const validateConstraints = (
  value: CampaignOptimizationConstraintsAugurSettings,
  config?: CampaignOptimizationConstraintsConfig
): DeepPartial<CampaignOptimizationConstraintsValidationError> | undefined => {
  const error: DeepPartial<CampaignOptimizationConstraintsValidationError> = {};

  value?.forEach((constraint) => {
    const constraintError = validateSingleConstraint(constraint, value, config);
    if (!_.isEmpty(constraintError)) {
      error.rows = {
        ...error.rows,
        [constraint.id]: constraintError,
      };
    }
  });
  return cleanErrorObject(error);
};

export const validateSingleConstraint = (
  constraint: Partial<Constraint>,
  allConstraints?: Partial<Constraint>[],
  config?: CampaignOptimizationConstraintsConfig
): {
  name?: string;
  description?: string;
  constraintType?: string;
  operator?: string;
  value?: string;
  constraintLevel?: string;
  channelId?: string;
  communicationId?: string;
  communicationGroupId?: string;
} => {
  const error = {};
  // Validate name
  const nameError = validateName(
    constraint.name,
    (allConstraints || [])
      .filter((x) => x.id !== constraint.id)
      .map((ch) => ch.name)
      .filter((name): name is string => name !== undefined)
  );
  // Validate description
  const descriptionError = validateDescription(constraint.description);

  // Validate constraintLevel
  const constraintLevelError = validateConstraintLevel(
    constraint.constraintLevel
  );

  // Validate channelId
  const channelIdError = validateChannelId(
    // @ts-ignore
    constraint.channelId,
    constraint.constraintLevel,
    config
  );

  // Validate communicationId
  const communicationIdError = validateCommunicationId(
    // @ts-ignore
    constraint.communicationId,
    constraint.constraintLevel,
    config
  );

  // Validate communicationGroupId
  const communicationGroupIdError = validateCommunicationGroupId(
    // @ts-ignore
    constraint.communicationGroupId,
    constraint.constraintLevel,
    config
  );

  // Validate constraintType
  const constraintTypeError = validateConstraintType(
    // @ts-ignore
    constraint.constraintType
  );

  // Validate operator
  const operatorError = validateOperator(
    // @ts-ignore
    constraint.operator
  );

  // Validate value
  const valueError = validateValue(
    // @ts-ignore
    constraint.value
  );
  return {
    name: nameError,
    description: descriptionError,
    constraintLevel: constraintLevelError,
    channelId: channelIdError,
    communicationId: communicationIdError,
    communicationGroupId: communicationGroupIdError,
    constraintType: constraintTypeError,
    operator: operatorError,
    value: valueError,
  };
};
