import { ExtendedAugurType } from 'common/dist/constants/augurTypes';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { validateAugurName } from 'common/dist/validation/augur';
import { MessageDescriptor } from 'react-intl';

import { checkAugurNameExists } from '../../../redux/modules/augur.checkNameExists.module';
import { ReduxActUnknown3 } from '../../../store/reduxAct';

// --- Field names
export const fieldAugurName = 'augurName';
export const fieldModuleType = 'moduleType';

// --- Type of the form values
export type DefaultFormValues = {
  [fieldAugurName]: string;
  [fieldModuleType]: ExtendedAugurType; // TODO
};

// --- Sync validation
type ErrorType = {
  [fieldName: string]:
    | MessageDescriptor
    | { [subField: string]: MessageDescriptor };
};
export const defaultValidate = (values: DefaultFormValues) => {
  const errors: ErrorType = {};

  // --- Validate Module Type
  if (!values[fieldModuleType]) {
    errors[fieldModuleType] = {
      id: 'no-id',
      defaultMessage: 'Please select a Module',
    };
  }

  // --- Validate Augur Name
  errors[fieldAugurName] = validateAugurName(values[fieldAugurName]);

  return errors;
};

// --- Async validation
/**
 * Form that async validation functions should take.
 * To make sense with multiple async validations we check for blurredField which is set if a field is in asyncBlurFields or asyncChangeFields
 * and is triggered. The other case is when it's called via handleSubmit in which case blurredField is undefined and everything should be validated
 * @param habitatCode
 */
export function getDefaultMpwAsyncValidate(habitatCode: string) {
  return (
    { augurName }: DefaultFormValues,
    dispatch: ToBeRefined,
    props: unknown,
    blurredField: string | undefined
  ) => {
    if (blurredField === undefined || blurredField === fieldAugurName) {
      return new Promise((resolve, reject) => {
        dispatch(
          (checkAugurNameExists as ReduxActUnknown3)(habitatCode, augurName, {
            resolve,
            reject,
          })
        );
      }).then(({ exists }) =>
        exists
          ? Promise.reject({
              augurName: {
                id: 'newAugur.stepOne.error.this_name_already_exists',
                name: augurName,
              },
            })
          : null
      );
    } else {
      return Promise.resolve();
    }
  };
}

export const defaultAsyncBlurFields = [fieldAugurName];
export const defaultAsyncChangeFields = [];
