import _ from 'lodash';
import moment from 'moment';
import validator from 'validator';

import { ACCURACY_DETAILS_TYPE } from '../constants/accuracyCharts';
import { timeFormat } from '../constants/constants';
import { scheduleMode, schedulePeriod } from '../constants/enums';
import { kpiOptionsGrouped } from '../constants/keyPerformanceIndicators';
import {
  PostAugurRequestBody,
  PutAugurRequestBody,
} from '../types/requestBodies/augurs';
import { ToBeRefined } from '../types/todo_type';

const isEmpty = (value: ToBeRefined): ToBeRefined =>
  value === undefined || value === null || value === '';

const ALLOWED_PERIODS = [
  schedulePeriod.HOURLY,
  schedulePeriod.DAILY,
  schedulePeriod.WEEKLY,
  schedulePeriod.MONTHLY,
];

const timeRe: ToBeRefined = {
  1: /^[012]$/,
  2: /^([01]?[0-9]|2[0-3])$/,
  3: /^([01]?[0-9]|2[0-3]):$/,
  4: /^([01]?[0-9]|2[0-3]):[0-5]$/,
  5: /^([01]?[0-9]|2[0-3]):[0-5][0-5]$/,
};

export const validateTime = (time: ToBeRefined, exact = true): ToBeRefined => {
  if (exact) {
    // Second check handles special case of 24:00 which moment deems valid, but automatically translates to 00:00
    const timeMoment = moment(time, timeFormat, true);
    return timeMoment.isValid() && timeMoment.format(timeFormat) === time;
  }

  switch (time.length) {
    case 0:
      return true;
    case 1:
    case 2:
    case 3:
    case 4:
      return timeRe[time.length].test(time);
    default: {
      const timeMoment = moment(time, timeFormat, true);
      return timeMoment.isValid() && timeMoment.format(timeFormat) === time;
    }
  }
};

export const validateSchedule = (
  { trigger, trigDelayed, period, periodValue, time, mode }: ToBeRefined = {},
  exact = true
): ToBeRefined => {
  const errors: ToBeRefined = {};
  if (!validator.isIn(mode, [scheduleMode.SCHEDULED, scheduleMode.MANUAL])) {
    errors.mode = { id: 'newAugur.schedule.error.mode_invalid' };
  }

  if (mode === scheduleMode.MANUAL) {
    return errors;
  }

  return errors;
};

export const validateAddAugur = (body: PostAugurRequestBody): ToBeRefined => {
  const errors: ToBeRefined = {};

  if (!body.name) {
    errors.augurName = 'AugurName is required';
  }

  return _.pickBy(errors, _.identity);
};

export const validateUpdateAugur = (body: PutAugurRequestBody): ToBeRefined => {
  let errors = {};

  if (body.name) {
    errors = {
      ...errors,
      ...validateAugurName(body.name),
    };
  }

  return errors;
};

export const validateUpdateMeta = (body: ToBeRefined): ToBeRefined => {
  const errors = {};

  if (body.predictionSchedule)
    Object.assign(errors, validateSchedule(body.predictionSchedule));
  if (body.evaluationSchedule)
    Object.assign(errors, validateSchedule(body.evaluationSchedule));

  return _.pickBy(errors, _.identity);
};

export const validateKpiThreshold = ({
  indicator,
  threshold,
}: ToBeRefined): ToBeRefined => {
  // @ts-ignore
  const options: ToBeRefined = kpiOptionsGrouped[indicator];

  if (!options) {
    return { id: 'augur.kpi.error.indicator_not_exist' };
  }

  if (+threshold > options.max) {
    return {
      id: 'augur.kpi.error.threshold_max',
      values: { max: options.max },
    };
  }

  if (+threshold < options.min) {
    return {
      id: 'augur.kpi.error.threshold_min',
      values: { min: options.min },
    };
  }

  return '';
};

// const augurNameRegexp = /^[\w\s\d[\]äöüßÄÖÜ]()+$/;
// const augurNameRegexp = /c/;
const augurNameRegexp = /^[a-zA-Z 0-9.,+\-äöüßÄÖÜ()]*$/;

export function validateAugurName(augurName: string): ToBeRefined {
  if (isEmpty(augurName)) {
    return { id: 'newAugur.stepOne.error.missing_augur_name' };
  } else if (augurName.length < 3 || augurName.length > 65) {
    return { id: 'newAugur.stepOne.error.augur_name_length' };
  } else if (!augurNameRegexp.test(augurName)) {
    return {
      id: 'newAugur.stepOne.error.it_can_contain_only_alphanumeric_symbols_and_spaces',
    };
  }

  return undefined;
}

export function validatePredictionTargets({
  predictionTargets,
}: ToBeRefined): ToBeRefined {
  const errors: ToBeRefined = {};

  if (isEmpty(predictionTargets) || !predictionTargets.length) {
    errors.predictionTargets = {
      id: 'newAugur.stepTwo.error.missing_prediction_targets',
    };
  }

  return errors;
}

export function validatePredictionSchedule(
  { predictionSchedule = {} }: ToBeRefined,
  exact = true
): ToBeRefined {
  const errors: ToBeRefined = {};

  if (isEmpty(predictionSchedule.mode)) {
    errors.predictionSchedule = {
      mode: { id: 'newAugur.stepThree.error.missing_prediction_mode' },
    };
  }

  if (predictionSchedule.mode === 'scheduled') {
    const predictionScheduleErrors = validateSchedule(
      predictionSchedule,
      exact
    );
    if (!_.isEmpty(predictionScheduleErrors)) {
      errors.predictionSchedule = validateSchedule(predictionSchedule, exact);
    }
  }

  return errors;
}

export function validateEvaluationSchedule(
  { evaluationSchedule }: ToBeRefined,
  exact = true
): ToBeRefined {
  const errors: ToBeRefined = {};

  if (!evaluationSchedule) {
    return errors;
  }

  errors.evaluationSchedule = validateSchedule(evaluationSchedule, exact);

  return errors;
}

export function validateKpiAndThreshold({
  learningKpi,
  learningThreshold,
}: ToBeRefined): ToBeRefined {
  const errors: ToBeRefined = {};

  if (isEmpty(learningKpi)) {
    errors.learningKpi = { id: 'augur.kpi.error.missing_kpi' };
  }

  if (isEmpty(learningThreshold) && parseFloat(learningThreshold) !== 0) {
    errors.learningThreshold = { id: 'augur.kpi.error.missing_threshold' };
  } else {
    errors.learningThreshold = validateKpiThreshold({
      indicator: learningKpi,
      threshold: learningThreshold,
    });
  }

  return errors;
}

export const validateStep2 = (values: ToBeRefined): ToBeRefined =>
  validatePredictionSchedule(values);
export const warnStep2 = (values: ToBeRefined): ToBeRefined =>
  validatePredictionSchedule(values, false);

export function validateSettings(values: ToBeRefined): ToBeRefined {
  return {
    ...validatePredictionSchedule(values),
    ...validateEvaluationSchedule(values),
    ...validateKpiAndThreshold(values),
  };
}

export function warnSettings(values: ToBeRefined): ToBeRefined {
  return {
    ...validatePredictionSchedule(values, false),
    ...validateEvaluationSchedule(values, false),
    ...validateKpiAndThreshold(values),
  };
}

export function validate(values: ToBeRefined): ToBeRefined {
  return {
    augurName: validateAugurName(values.augurName),
    ...validatePredictionTargets(values),
    ...validatePredictionSchedule(values),
  };
}

export const validateAccuracyDetailsParams = (
  query: ToBeRefined
): ToBeRefined => {
  const errors: ToBeRefined = {};
  if (!Object.values(ACCURACY_DETAILS_TYPE).includes(query.type)) {
    errors.type = `Invalid type '${query.type}'`;
  }

  const limit = +query.limit;
  if (!limit || limit <= 0) {
    errors.limit = 'limit should be positive number';
  }

  return errors;
};

export function validateBinaryEventBased(values: ToBeRefined): ToBeRefined {
  return {
    augurName: validateAugurName(values),
    ...validatePredictionTargets(values),
    ...validatePredictionSchedule(values),
  };
}
