import { all } from 'redux-saga/effects';

import watchFormSubmitSaga from './onSubmitActions.saga';
import { watchAddHabitat } from '../modules/habitat.add.module';
import {
  watchDeleteHabitat,
  watchUpdateHabitat,
} from '../modules/habitat.modify.module';
import { watchCheckAugurNameExists } from '../modules/augur.checkNameExists.module';
import {
  watchChangePassword,
  watchChangePasswordAsAdmin,
  watchSaveLanguage,
} from '../modules/account.module';
import { watchFetchUser } from '../modules/user.module';
import {
  watchEnsureHabitatInSummary,
  watchLoadHabitat,
} from '../modules/habitat.module';
import { watchSearch } from '../modules/dashboard.search.module';

import {
  watchFetchSummary,
  watchNewAugurAdded,
  watchNewHabitatAdded,
} from '../modules/dashboard.summary.module';
import { watchSendNotification } from '../modules/notifications.module';
import {
  watchPageDataLoad,
  watchShowErrorPage,
} from '../modules/page.module.js';
import { watchChangeLocale } from '../modules/locale.module';
import { watchGetConfig } from '../modules/config.module';

import {
  watchAddGroupPermission,
  watchAddRealmRoleMapping,
  watchAddUserPermission,
  watchAdminAddGroup,
  watchAdminAddUser,
  watchAdminLoadUserDetails,
  watchAdminUpdateUser,
  watchFetchGroupPolicy,
  watchFetchNamesForAdminPermission,
  watchFetchUserPolicy,
  watchJoinGroup,
  watchLeaveGroup,
  watchLoadGroupMembers,
  watchLoadGroups,
  watchLoadRoles,
  watchLoadUsersList,
  watchRemoveGroupPermission,
  watchRemoveRealmRoleMapping,
  watchRemoveUserPermission,
} from '../modules/admin.users.module';
import { watchLoadLoginHistory } from '../modules/account.loginHistory.module';

import {
  watchFormReset,
  watchFormUpdateErrors,
  watchUpdateFormSteps,
} from '../modules/newWizard.module';

import { watchUpdateProfileInfo } from '../modules/account.info.module';

import { watchDashboardInit } from '../modules/dashboard.init.module';

import {
  watchDataImportChanged,
  watchDataImportReset,
} from '../modules/dataImport.module';

import { watchPredictionStarted } from '../modules/predictions.module';

import {
  watchCancelJob,
  watchCancelJobGroup,
  watchFetchPreQueue,
} from '../modules/orchestration.queue.module';
import {
  watchConfirmEditCm,
  watchFetchJobConfigs,
} from '../modules/orchestration.jobconfigs.module';
import {
  watchFetchHistory,
  watchFetchJobGroupDetailsHistory,
} from '../modules/orchestration.history.module';
import {
  watchAddSchedule,
  watchDeleteSchedule,
  watchFetchSchedule,
  watchFetchSchedules,
  watchUpdateSchedule,
} from '../modules/orchestration.schedules.module';

import { workbenchSagas } from '../workbench/saga';
import {
  watchFetchAugurName,
  watchFetchRepositoryName,
} from '../modules/orchestration.names.module';
import {
  watchFetchCodeCapsuleExecutions,
  watchFetchCodeCapsules,
  watchFetchSingleCodeCapsule,
} from '../modules/codeCapsules.module';
import {
  watchFetchJobDetails,
  watchFetchJobLogs,
  watchFetchLogsOrchestration,
  watchJobKubernetesEvents,
  watchProgressSteps,
} from '../modules/orchestration.jobdetails.module';
import {
  watchFetchCodeCapsuleOutput,
  watchFetchCodeCapsuleReport,
} from '../modules/orchestration.codeCapsuleReport.module';
import {
  watchTriggerEvaluationJob,
  watchTriggerLearningJob,
  watchTriggerPredictionJob,
  watchTriggerRunCodeCapsuleJob,
} from '../modules/triggerJobs.module';
import {
  watchFetchAppPermissions,
  watchFetchApps,
  watchFetchAppsAdmin,
  watchFetchAppVersions,
} from '../modules/apps.module';
import {
  watchFetchRunningApps,
  watchFetchRunningWorkbench,
  watchStopApp,
  watchStopWorkbench,
} from '../../store/admin/resources/saga';
import {
  watchCreateBackup,
  watchCreateTemplate,
  watchFetchBackups,
  watchFetchTemplates,
  watchRestoreBackup,
} from '../../store/admin/backups/saga';
import {
  watchAddDataSource,
  watchDeleteDataSource,
  watchFetchDataSources,
  watchFetchDataSourceSettings,
  watchUpdateDataSource,
} from '../modules/data.dataSources.module';
import { watchFetchComponentPermissions } from '../modules/dashboard.module';
import {
  watchCommitUpload,
  watchFetchCassandraCredentials,
  watchFetchCassandraKeyspaces,
  watchFetchCassandraPermissions,
  watchFetchCassandraTables,
  watchFetchCassandraTableSample,
  watchKeyspaceCreated,
  watchListenToFileInfo,
  watchListenToWriteInfo,
  watchRequestCommitInfo,
  watchUploadFile,
} from '../modules/data.cassandra.module';
import {
  watchDeleteObject,
  watchFetchBucketContent,
  watchFetchBuckets,
  watchFetchPreview,
  watchFetchS3Credentials,
  watchFetchS3Permissions,
  watchMoveObject,
  watchUploadFile as watchUploadFileS3,
  watchValidatePath,
} from '../modules/data.s3.module';
import {
  watchDeleteAvatar,
  watchFetchAvatar,
  watchUploadAvatar,
  watchUploadAvatarSuccess,
} from '../modules/account.avatar.module';
import { watchCheckRepoNameExists } from '../modules/repo.checkNameExists.module';
import { watchCheckHabitatNameExists } from '../modules/habitat.checkNameExists.module';
import { watchEnableMaintenance } from '../../store/admin/maintenance/saga';
import { watchInvalidateQueries } from '../modules/reactQuery.module';

export default function* root() {
  yield all([
    watchInvalidateQueries(),
    watchFormSubmitSaga(),
    watchAddHabitat(),
    watchUpdateHabitat(),
    watchDeleteHabitat(),
    watchCheckAugurNameExists(),
    watchCheckHabitatNameExists(),
    watchCheckRepoNameExists(),
    watchFetchUser(),
    watchChangePassword(),
    watchChangePasswordAsAdmin(),
    watchSaveLanguage(),
    watchLoadHabitat(),
    watchEnsureHabitatInSummary(),
    watchSearch(),
    watchFetchSummary(),
    watchNewAugurAdded(),
    watchNewHabitatAdded(),
    watchSendNotification(),
    watchPageDataLoad(),
    watchShowErrorPage(),
    watchLoadLoginHistory(),
    watchChangeLocale(),
    watchGetConfig(),
    watchLoadUsersList(),
    watchAdminAddUser(),
    watchAdminAddGroup(),
    watchAdminLoadUserDetails(),
    watchLoadRoles(),
    watchAdminUpdateUser(),
    watchAddRealmRoleMapping(),
    watchRemoveRealmRoleMapping(),
    watchJoinGroup(),
    watchLeaveGroup(),
    watchAddGroupPermission(),
    watchRemoveGroupPermission(),
    watchAddUserPermission(),
    watchRemoveUserPermission(),
    watchFetchUserPolicy(),
    watchFetchGroupPolicy(),
    watchLoadGroups(),
    watchLoadGroupMembers(),
    watchUpdateFormSteps(),
    watchFormReset(),
    watchFormUpdateErrors(),
    watchUpdateProfileInfo(),
    watchDashboardInit(),
    watchPredictionStarted(),
    watchDataImportReset(),
    watchDataImportChanged(),

    watchFetchPreQueue(),
    watchCancelJob(),
    watchCancelJobGroup(),
    watchFetchJobConfigs(),
    watchFetchHistory(),
    watchFetchJobGroupDetailsHistory(),
    watchFetchSchedules(),
    watchFetchSchedule(),
    watchDeleteSchedule(),
    watchAddSchedule(),
    watchUpdateSchedule(),
    watchConfirmEditCm(),
    // Workbench Sagas
    ...workbenchSagas(),
    watchFetchAugurName(),
    watchFetchRepositoryName(),
    watchFetchCodeCapsules(),
    watchFetchSingleCodeCapsule(),
    watchFetchCodeCapsuleExecutions(),
    watchFetchJobDetails(),
    watchFetchJobLogs(),
    watchJobKubernetesEvents(),
    watchProgressSteps(),
    watchFetchLogsOrchestration(),
    watchFetchCodeCapsuleReport(),
    watchFetchCodeCapsuleOutput(),
    watchTriggerLearningJob(),
    watchTriggerEvaluationJob(),
    watchTriggerPredictionJob(),
    watchTriggerRunCodeCapsuleJob(),
    watchFetchApps(),
    watchFetchAppsAdmin(),
    watchFetchAppVersions(),
    watchFetchAppPermissions(),
    watchFetchRunningWorkbench(),
    watchStopWorkbench(),
    watchFetchRunningApps(),
    watchStopApp(),
    watchFetchBackups(),
    watchRestoreBackup(),
    watchCreateBackup(),
    watchEnableMaintenance(),
    watchFetchTemplates(),
    watchCreateTemplate(),
    watchFetchComponentPermissions(),
    watchFetchDataSources(),
    watchFetchDataSourceSettings(),
    watchUpdateDataSource(),
    watchAddDataSource(),
    watchDeleteDataSource(),
    watchFetchCassandraCredentials(),
    watchFetchCassandraKeyspaces(),
    watchFetchCassandraPermissions(),
    watchFetchCassandraTables(),
    watchFetchCassandraTableSample(),
    watchUploadFile(),
    watchListenToFileInfo(),
    watchRequestCommitInfo(),
    watchCommitUpload(),
    watchListenToWriteInfo(),
    watchFetchBuckets(),
    watchFetchPreview(),
    watchFetchBucketContent(),
    watchUploadFileS3(),
    watchFetchS3Permissions(),
    watchFetchS3Credentials(),
    watchKeyspaceCreated(),
    watchValidatePath(),
    watchDeleteObject(),
    watchMoveObject(),
    watchUploadAvatar(),
    watchFetchAvatar(),
    watchDeleteAvatar(),
    watchUploadAvatarSuccess(),
    watchFetchNamesForAdminPermission(),
  ]);
}
