import React, { FC } from 'react';

import styles from './styles.module.scss';
import BackTo from '../../atoms/back-to/BackTo';
import ElementConfigurationPage from '../../molecules/element-configuration/ElementConfigurationPage';
import ElementGallery, {
  GalleryEntry,
} from '../../molecules/element-gallery/ElementGallery';
import { PAYLOAD_CATEGORY } from '../../molecules/grid-layout-editor/type';

export type Props = {
  selectedElementId: string;
  setSelectedElementId: (id: string) => void;
  elements: GalleryEntry[];
  isReportPage: boolean;
  sampleReport?: Record<string, Record<string, unknown>>;
  sampleSettings?: Record<string, unknown>;
};

const DevAugurSidebar: FC<Props> = (props) => {
  const {
    selectedElementId,
    setSelectedElementId,
    elements,
    isReportPage,
    sampleReport,
    sampleSettings,
  } = props;

  if (selectedElementId != null) {
    return (
      <div className={styles.configPaneContainer}>
        <div className={styles.backTo}>
          <BackTo
            label={'Back to Element Gallery'}
            onClick={() => setSelectedElementId(undefined)}
          />
        </div>

        <hr className={styles.spacer} />

        <ElementConfigurationPage
          key={selectedElementId} // used to rerender config component on element selection change
          selectedElementId={selectedElementId}
          sampleReport={sampleReport}
          sampleSettings={sampleSettings}
        />
      </div>
    );
  } else {
    return (
      <ElementGallery
        elements={elements}
        category={
          isReportPage ? PAYLOAD_CATEGORY.REPORT : PAYLOAD_CATEGORY.SETTINGS
        }
      />
    );
  }
};

export default DevAugurSidebar;
