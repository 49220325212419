export const ACCURACY_DETAILS_TYPE = {
  LIFT: 'lift',
  CUMULATIVE_LIFT: 'cumulativeLift',
  RESPONSE: 'response',
  CUMULATIVE_RESPONSE: 'cumulativeResponse',
  CAPTURED_RESPONSE: 'capturedResponse',
  CUMULATIVE_CAPTURED_RESPONSE: 'cumulativeCapturedResponse',
  ROC: 'roc',
  MISCLASSIFICATION: 'misclassification',
  PREDICTED_VALUES_DISTRIBUTION: 'predictedValuesDistribution',
} as const;
export type AccuracyDetailsType =
  typeof ACCURACY_DETAILS_TYPE[keyof typeof ACCURACY_DETAILS_TYPE];

export type AccuracyDetailsKeys = keyof typeof ACCURACY_DETAILS_TYPE;
