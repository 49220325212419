import { ImageReportData } from './single/Image';
import { imageImg } from './single/img/imageImg';
import { Config } from '../../../../../pages/augur/type';
export type { ImageReportData };

export type ImageConfig = {};

export type ConfigImageConfig = Config<ImageConfig>;

export const configTypeImage = `{}`;

export const reportDataTypeImage = `{
  image: string;
}`;

export const defaultConfigImage: ConfigImageConfig = {};

export const defaultReportDataDefaultImage: ImageReportData = {
  image: imageImg,
};
